.table.table-shrink {
  width: auto;
}

.table-sky {
  @extend .table;
  @extend .table-bordered;
  @extend .table-striped;
  @extend .table-sm;
  width: auto;

  .btn {
    padding: {
      top: .1rem;
      bottom: .1rem;
    }
  }

  td,
  th {
    border-color: #ddd;
  }

  th {
    padding: 10px;
  }
}

.report-table-bordered {
  @extend .table-bordered;

  td,
  th {
    border: 1px solid #bfbfbf;
  }

  thead th {
    border: 1px solid #bfbfbf;
  }
}

.single-row-maintainer {

  .form-control[readonly] {
    @extend .form-control-plaintext;
    background-color: transparent;
  }
}

@include media-breakpoint-down(md) {
  .mrc-font-adjust-sm {
    font-size: .8rem;
  }
}

@include media-breakpoint-down(lg) {
  .mrc-font-adjust-md {
    font-size: .8rem;
  }
}

@include media-breakpoint-down(xl) {
  .mrc-font-adjust-lg {
    font-size: .8rem;
  }
}



/**
 * Override bootstrap padding on tables and buttons
 */
.table th,
.table-sky th,
.table td {
  padding: .5rem;
}

.btn {
  padding: 0.275rem 0.5rem
}
/**For wkhtmltopdf renderer**/
.btn-group{
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
}
/**If it is inside a modal, just make the form 100%**/
.modal .mrc-form-width.col-md-6{
  flex: 0 0 100%;
  max-width: 100%;
}

